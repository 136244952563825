.arrow {
  transition: 0.2s
}

.active {
  transform: rotate(90deg);
  transition: 0.2s
}

.collapse {
  margin-top: 10px;
  margin-left: 35px;
}
