.badge {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #228be6;
  position: absolute;
  top: 2px;
  left: 3px;
}

.dropdown {
  box-sizing: border-box;
  max-height: calc(100vh - var(--mantine-header-height));
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
}

.content {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: -16px;
  padding-right: 16px;
}

.removeBtn {
  &:hover {
    color: red;
  }
}
