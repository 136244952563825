.header {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.35;
}

.titleBack {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: 6px;
  }

  &:hover {
    color: #228be6;
  }
}
