.tableWrapper {
    overflow-y: auto;
  }
  
  .table {
    thead {
      position: sticky;
      top: 0;
    }
  
    tr {
      td
      {
        p {
          width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0;
        }
  
  
        &:first-child {
          padding-left: 20px;
          max-width: 30px;
          min-width: 30px;
        }
      }
  
      th {
        width: 120px;
  
        &:first-child {
          padding-left: 20px;
          max-width: 30px;
          min-width: 30px;
        }
      }
    }
  }
  