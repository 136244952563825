.drawer {
  display: flex;
  flex-direction: column;

  > * {
    flex-shrink: 0;
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.45;
}

.form {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > * {
    flex-shrink: 0;
  }
}

.selectDropdown {
  * {
    box-sizing: border-box;
  }
}

.textarea {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > * {
    flex-shrink: 0;
  }
}

.textareaWrapper {
  flex-grow: 1;

  textarea {
    height: 100%;
  }
}

